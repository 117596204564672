import axios from "axios";
import store from "@/plugins/store";
import AuthService from "@/services/auth.service";
const API_BASE_URL = store.state.api_backend;

const ApiRequest = axios.create({
    baseURL: API_BASE_URL ,
    headers:{
        'Content-Type': 'application/json',
        "Authorization": AuthService.getUserToken(),
    }
});
const AmphiEvents = () =>{
    return{
        list: () =>  ApiRequest.get('api/amphievent'),
        retrieve: (id) => ApiRequest.get(`api/amphievent/${id}`),
        create:(params) => ApiRequest.post('api/amphievent',params),
        update: (id,params) => ApiRequest.post(`api/amphievent/${id}`,{...params, _method:'PUT'}),
        delete:(id) => {
            return ApiRequest.delete(`api/amphievent/${id}`)
        },
        next_event: () => ApiRequest.get('api/next-event'),
        get_reservations: () => ApiRequest.get('api/reservations'),
        create_admin: (params) => ApiRequest.post('api/reservations', params)
    }
}
const AmphiSchedules = () => {
    return{
        get: () => ApiRequest.get('api/eventschedule'),
        create: (params) => ApiRequest.post('/api/eventschedule', params),
        retrieve: (id) => ApiRequest.get(`/api/eventschedule/${id}`),
        reservations: (id) => ApiRequest.get(`/api/eventschedule/${id}/reservations`),
        delete:(id) => {
            return ApiRequest.delete(`/api/eventschedule/${id}`)
        },
        masiveCreate:(file,delimiter = ';') => {
            const form = new FormData()
            form.append('csv_import', file)
            form.append('delimiter', delimiter)
            return ApiRequest
                .post(
                    `/api/eventschedule/csv`,
                    form,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
        },
    }

}

const AmphiPoster = () => {
   return {
       create: (file) => {
           const form = new FormData()
           form.append('poster', file)
           return ApiRequest
               .post(
                   `/api/poster`,
                   form,
                   {
                       headers: {
                           'Content-Type': 'multipart/form-data'
                       }
                   })
       }
   }
}

export {
    AmphiEvents,
    AmphiSchedules,
    AmphiPoster
}

